import { batchItemSituations } from "@/modules/stock/batch/enums/batchItemSituations.enum";

export const reconditioningSituations = {
  TO_RECONDITION: {
    ...batchItemSituations.TO_RECONDITION,
    label: "À recondicionar"
  },
  TESTING: {
    ...batchItemSituations.TESTING,
    label: "Testagem"
  },
  IN_RECONDITION: {
    ...batchItemSituations.IN_RECONDITION,
    label: "Em recondicionamento"
  },
  RECONDITIONED: {
    ...batchItemSituations.RECONDITIONED,
    label: "Recondicionado"
  },
  TO_DEMANUFACTURE: {
    ...batchItemSituations.TO_DEMANUFACTURE,
    label: "Devolver para Desmanufatura"
  }
  // RETURNED: {
  //   ...batchItemSituations.RETURNED,
  //   label: "Devolvido"
  // }
};
