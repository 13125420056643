export const inventoryMoveType = {
  IN: {
    id: "E",
    description: "Entrada",
    label: "Adicionar ao Estoque"
  },
  OUT: {
    id: "S",
    description: "Saída",
    label: "Retirada para Conserto de Equipamento"
  }
};
