<template>
  <v-container class="content-page">
    <title-header :title="$route.meta.title" />
    <v-row>
      <v-col cols="12" lg="6">
        <v-card>
          <v-card-title>Componentes:</v-card-title>

          <validation-observer>
            <v-form
              @submit.prevent="handleSubmitAddComponent"
              slot-scope="{ invalid }"
            >
              <div class="mx-4">
                <part-picker
                  v-model="form.part"
                  :key="`part-picker-${insertKey}`"
                  :fromInventory="partPickerFromInventory"
                  :readonly="partPickerReadonly"
                />

                <template>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <field
                        v-model="form.part.brand.description"
                        type="genericText"
                        label="Marca"
                        name="brand"
                        :disabled="true"
                        :isRequired="true"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <field
                        v-model="form.part.model"
                        type="genericText"
                        label="Modelo"
                        name="model"
                        :disabled="true"
                        :isRequired="true"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col v-if="form.part.stockAmount" cols="12" md="4">
                      <field
                        v-model="form.part.stockAmount"
                        type="genericText"
                        label="Disponível em Estoque"
                        name="amount"
                        :disabled="true"
                        :isRequired="true"
                      />
                    </v-col>
                    <v-col cols="12" :md="form.part.stockAmount ? 8 : 12">
                      <field
                        v-model="form.amount"
                        type="number"
                        label="Quantidade"
                        name="amount"
                        :key="`field-amount-${insertKey}`"
                        :customError="amountError"
                        :maxValue="
                          validateMax ? form.part.stockAmount : Number.MAX_VALUE
                        "
                        :minValue="1"
                        :isRequired="true"
                      />
                    </v-col>
                  </v-row>
                </template>
              </div>

              <v-card-actions class="d-flex flex-wrap justify-space-between">
                <v-btn
                  type="button"
                  color="#ffffff"
                  class="ma-0 mt-1 black-3--text cancel-button"
                  :block="$isMobile"
                  @click="$router.back()"
                  >Cancelar</v-btn
                >
                <v-btn
                  type="submit"
                  color="secondary-color-1"
                  class="ma-0 mt-1 white--text next-step-button"
                  :block="$isMobile"
                  :disabled="invalid"
                  >Adicionar</v-btn
                >
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card>
          <v-card-title class="mb-2">Resumo da Requisição:</v-card-title>

          <v-card-text class="my-2">
            <p>
              Tipo da requisição: <strong>{{ type.label }}</strong>
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <div class="selected-parts-container">
            <div v-if="selectedParts.length">
              <v-data-table
                :headers="headers"
                :items="selectedParts"
                :items-per-page="selectedParts.length"
                hide-default-footer
              >
                <template v-slot:[`item.partId`]="{ item }">
                  <div>
                    <v-icon
                      class="pointer"
                      color="error"
                      @click="prepareToRemoveItemFromList(item)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </div>
                </template>
              </v-data-table>
            </div>

            <div v-if="!selectedParts.length" class="mt-5">
              <part-not-found :title="dataNotFound" />
            </div>
          </div>

          <v-card-actions class="d-flex flex-wrap justify-end">
            <v-btn
              type="submit"
              color="secondary-color-1"
              class="ma-0 mt-1 white--text cancel-button"
              :block="$isMobile"
              :disabled="saveActionInvalid"
              @click="handleSubmitChangeSituation"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import PartNotFound from "@/modules/stock/batch/views/components/PartNotFound";
  import PartPicker from "@/modules/stock/batch/views/components/part-picker/PartPicker";
  import Field from "@/modules/core/views/components/Field";

  import { inventoryMoveType } from "@/modules/stock/parts-inventory/enums/inventoryMoveType";
  import { reconditioningSituations } from "@/modules/stock/reconditioning/enums/reconditioningSituations.enum";

  import { ValidationObserver } from "vee-validate";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "RequireParts",

    components: {
      TitleHeader,
      PartNotFound,
      PartPicker,
      Field,
      ValidationObserver
    },

    data: () => ({
      form: {
        part: {
          id: undefined,
          partType: undefined,
          stockId: undefined,
          stockAmount: undefined,
          brand: {
            id: undefined,
            description: undefined
          },
          model: undefined
        },
        amount: undefined
      },

      insertKey: 1,

      selectedParts: [],

      headers: [
        {
          text: "Componente",
          align: "start",
          value: "partType"
        },
        { text: "Marca", value: "brand" },
        { text: "Modelo", value: "model" },
        { text: "Quantidade", value: "amount" },
        { value: "partId" }
      ],

      dataNotFound: ["Você ainda não selecionou", "nenhum componente"],

      types: {
        [inventoryMoveType.OUT.id]: {
          ...inventoryMoveType.OUT
        },
        [inventoryMoveType.IN.id]: {
          ...inventoryMoveType.IN
        }
      }
    }),

    computed: {
      ...mapGetters("stock/reconditioning", {
        changeSituationData: "getChangeSituation"
      }),

      amountError() {
        if (!this.form?.part?.id && this.form.amount) {
          return "Selecione uma peça";
        }
        return null;
      },

      saveActionInvalid() {
        return !this.selectedParts.length;
      },

      type() {
        return this.types[this.getType()];
      },

      isMoveOut() {
        return this.type.id === inventoryMoveType.OUT.id;
      },

      isMoveIn() {
        return this.type.id === inventoryMoveType.IN.id;
      },

      validateMax() {
        return this.isMoveOut;
      },

      partPickerReadonly() {
        return this.isMoveOut;
      },

      partPickerFromInventory() {
        return this.isMoveOut;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/reconditioning", ["changeSituation"]),

      getType() {
        if (
          this.changeSituationData.situationId ===
          reconditioningSituations.IN_RECONDITION.id
        ) {
          return inventoryMoveType.OUT.id;
        }
        if (
          this.changeSituationData.situationId ===
          reconditioningSituations.TO_DEMANUFACTURE.id
        ) {
          return inventoryMoveType.IN.id;
        }
      },

      handleSubmitAddComponent() {
        this.addFormToList();
        this.clearForm();
        this.insertKey++;
      },

      addFormToList() {
        const formattedFormData = this.formatFormToList();
        if (
          this.selectedParts.some(
            part => part.partId === formattedFormData.partId
          )
        ) {
          this.removeFromList({ partId: formattedFormData.partId });
        }
        this.selectedParts.push(formattedFormData);
      },

      clearForm() {
        this.form = {
          part: {
            id: undefined,
            partType: undefined,
            stockId: undefined,
            stockAmount: undefined,
            brand: {
              id: undefined,
              description: undefined
            },
            model: undefined
          },
          amount: undefined
        };
      },

      formatFormToList() {
        return {
          partId: this.form.part.id,
          stockId: this.form.part.stockId,
          partType: this.form.part.partType.description,
          brand: this.form.part.brand.description,
          model: this.form.part.model,
          amount: parseInt(this.form.amount, 10)
        };
      },

      prepareToRemoveItemFromList(item) {
        this.$root.$confirm
          .open(
            "Remover componente",
            "O componente será removido da requisição"
          )
          .then(accepted => {
            if (accepted) {
              this.removeFromList(item);
            }
          });
      },

      removeFromList(item) {
        this.selectedParts = this.selectedParts.filter(
          part => part.partId !== item.partId
        );
      },

      formatParts(fromStock) {
        return this.selectedParts.map(part => {
          return {
            id: fromStock ? part.stockId : part.partId,
            amount: part.amount
          };
        });
      },

      mountBodyPayloadChangeSituation() {
        const body = {
          situation: {
            id: this.changeSituationData.situationId
          },
          amount: this.changeSituationData.amount
        };

        if (this.type.id === inventoryMoveType.OUT.id) {
          body.stocks = this.formatParts(true);
        }

        if (this.type.id === inventoryMoveType.IN.id) {
          body.parts = this.formatParts(false);
        }

        return body;
      },

      mountPayloadChangeSituation() {
        return {
          id: this.changeSituationData.batchItemId,
          body: this.mountBodyPayloadChangeSituation()
        };
      },

      async handleSubmitChangeSituation() {
        try {
          const payload = this.mountPayloadChangeSituation();
          const response = await this.changeSituation(payload);

          this.toggleSnackbar({
            text: response?.message ?? "Registro atualizado com sucesso",
            type: "success"
          });

          return this.$router.push({
            name: "reconditioning"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .content-page {
    width: 70%;
  }

  .selected-parts-container {
    min-height: 267px;
  }
</style>
